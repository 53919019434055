import styled from 'styled-components'
import theme from 'styles/theme'

export const Projects = styled.ul`
  padding: 0;
`

export const Project = styled.li`
  list-style: none;
  margin-bottom: 3rem;
`

export const Skills = styled.ul`
  padding: 0;
`

export const Skill = styled.li`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: ${theme.color.accent.lightBlue};
  color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  display: inline-block;
`
