import React from 'react'
import Layout from 'components/layout'
import data from './data'
import { Projects, Project, Skills, Skill } from './styles'

const CV = () => {
  return (
    <Layout>
      <h1>Konstantin Lebedev</h1>
      <section>
        <h2>🎓 Education</h2>
        <p>
          <strong>Ural State Technical University</strong>
          <br />
          Master's degree, Information Technology
          <br />
          2008 - 2013
        </p>
      </section>
      <section>
        <h2>👨‍💻 Skills</h2>
        <Skills>
          {data.skills.map(skill => (
            <Skill key={skill}>{skill}</Skill>
          ))}
        </Skills>
      </section>
      <section>
        <h2>🌎 Languages</h2>
        <ul>
          <li>
            🇬🇧 English (
            <a
              href="https://drive.google.com/file/d/1Y6iI7pfWU6i55UFuCw-km-5aF72XEWzr/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              fluent
            </a>
            )
          </li>
          <li>🇷🇺 Russian (native)</li>
          <li>🇪🇸 Spanish (intermediate)</li>
        </ul>
      </section>
      <section>
        <h2>🖥 Past projects</h2>
        <Projects>
          {data.projects.map(project => (
            <Project key={project.title}>
              <p>
                <strong>{project.title}</strong> (
                <a href={project.link} target="_blank" rel="noreferrer">
                  {project.link}
                </a>
                )
                <br />
              </p>
              <p>{project.description}</p>
              <Skills>
                {project.tags.map(tag => (
                  <Skill key={tag}>{tag}</Skill>
                ))}
              </Skills>
            </Project>
          ))}
        </Projects>
      </section>
    </Layout>
  )
}

export default CV
