type Project = {
  title: string
  link: string
  description: string
  tags: readonly string[]
}

const skills = [
  'JavaScript',
  'TypeScript',
  'React',
  'Node.JS',
  'Next.JS',
  'Gatsby.JS',
  'Storybook',
  'CSS',
  'HTML',
  'Redux',
  'CSS-in-JS',
  'GraphQL',
]

const projects: readonly Project[] = [
  {
    title: 'Honeydrip',
    description: 'Platform providing subscription-based content for fans.',
    link: 'https://honeydrip.com/',
    tags: ['TypeScript', 'React', 'styled-components', 'Next.JS'],
  },
  {
    title: 'Magiclytics',
    description: 'A dashboard for online influencers',
    link: 'https://magiclytics.com/',
    tags: ['TypeScript', 'React', 'styled-components', 'storybook'],
  },
  {
    title: 'Fellow',
    description:
      'Fellow is the company that provides on-demand fertility testing kits for men. This project was built from scratch, with high focus on performance, accessibility and SEO-friendliness. It uses progressive image loading and statically built blog to ensure the maximum performance and highest Lighthouse scores.',
    link: 'https://www.meetfellow.com/',
    tags: ['TypeScript', 'React', 'Gatsby', 'Stripe'],
  },
  {
    title: 'DashNexus',
    description:
      'DashNexus is a next generation governance platform for Dash cryptocurrency that allows the community to submit and vote on proposals that sustain and push forward Dash coin. In addition to complex charts and calculations, the project involved work with crypto protocols on the client-side, such as decryption of master keys, encryption and signing of payload to be submitted to the blockchain, and signature verification for submitted proposals. Application was built from the ground up.',
    link: 'https://app.dashnexus.org',
    tags: [
      'TypeScript',
      'React',
      'Redux',
      'Blockhain',
      'CryptoJS',
      'styled-components',
    ],
  },
  {
    title: 'Hoodline',
    description:
      'Hoodline helps discover and create engaging content related to your region. On this project I revamped the UI of the website, migrated older codebase to new React code, and increased SEO scores for articles by adding structured data and AMP pages.',
    link: 'https://hoodline.com/',
    tags: ['JavaScript', 'React', 'AMP', 'styled-components'],
  },
  {
    title: 'Minted',
    description:
      'Minted is a marketplace for buying and selling customizable products created by independent artists. On this project I redesigned main checkout flow, created a more pleasing and straightforward UI devoid of the previous clutter.',
    link: 'https://www.minted.com/',
    tags: ['JavaScript', 'React', 'SCSS', 'HTML'],
  },
]

export default {
  skills,
  projects,
}
